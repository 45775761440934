export default class AppConfig {

    constructor() {

        // eslint-disable-next-line no-restricted-globals
        const domain = typeof window !== 'undefined' ? window.location.hostname : self.location.hostname;
        this.contentfulEnv = "develop"
        this.dbEnv = "dev"
        this.pubnub = {
            publishKey: "pub-c-86eac69c-a141-425d-b12d-2eeefc8146af",
            subscribeKey: "sub-c-2bbfe53f-d3c1-46bc-8b6f-599ad5ff95bb",
            suppressLeaveEvents: true
        }
        this.googleApiKey = "AIzaSyC2Sgtpsu9QDlS8Hwg6_F0ZRSAWm4u2g6o"
        this.dbCred = {
            apiKey: "AIzaSyBwPozE_5uwQl7ecpne-UQkglkTbghdJ_g",
            authDomain: "mential-dev.firebaseapp.com",
            projectId: "mential-dev",
            storageBucket: "mential-dev.appspot.com",
            messagingSenderId: "711807229955",
            appId: "1:711807229955:web:180bb32508b36e93cf6e36",
            measurementId: "G-2HLSQPMY8X",
            databaseURL: "https://mential-dev-default-rtdb.europe-west1.firebasedatabase.app"
        }


        this.bucketRecords = "gs://mential-sessions-records-dev"
        this.apihostEnv = "https://mential-service-yg6wbmxwwq-ew.a.run.app"
        this.calendarApihostEnv = "https://mential-calendar-yg6wbmxwwq-ew.a.run.app"
        this.messagingApiHostEnv = "https://mential-messaging-yg6wbmxwwq-ew.a.run.app"
        this.cloudMessagingVapidKey = "uQtrCOgMJGWcLYlN2dqQFVY3oHZvSFZAxQaKIaPUlVk"
        this.apihostEnv = "http://localhost:8080"

        //this.apihostEnv = "https://mential-service-xnyvsi5jla-ew.a.run.app"


        if (domain.indexOf("app.mential.io") > -1) {
            this.contentfulEnv = "master";
            this.dbEnv = "prod"
            this.apihostEnv = "https://api.mential.io"
            this.googleApiKey = "AIzaSyD2nyilznmFUXJvgFv2zN7vHKlJHg7Ptl4"
            this.dbCred = {
                apiKey: "AIzaSyDtOhdmukd1X5J0jHGc3OfvXNDdp2pAVIk",
                authDomain: "auth.mential.io",
                projectId: "mentialtech",
                storageBucket: "mentialtech.appspot.com",
                messagingSenderId: "752871396907",
                appId: "1:752871396907:web:a84cf8e950363ba25efc6e",
                measurementId: "G-YT73BYD9GW",
                databaseURL: "https://mentialtech-default-rtdb.europe-west1.firebasedatabase.app"

            }
            this.pubnub = {
                publishKey: "pub-c-faaa9710-472c-4545-b870-519ada96becb",
                subscribeKey: "sub-c-1c214631-3468-4c44-bf64-900a211a3d7b",
                suppressLeaveEvents: true
            }
            this.bucketRecords = "gs://mential-sessions-records"
            this.calendarApihostEnv = "https://mential-calendar-a52zfefqfq-ew.a.run.app"
            this.messagingApiHostEnv = "https://mential-messaging-a52zfefqfq-ew.a.run.app"
            this.cloudMessagingVapidKey = "BCDrksqs5sBeMt7yrJr0H3UQWrV1PzzQEE4Q64JZT1mdiE4GWLvsAEuxLhgvqYaknTBg1iT_4jWSbk2zPHuK0qU"

            //   } else if (domain.indexOf("mential-uat.web.app") > -1) {
        } else {
            this.apihostEnv = "https://mential-service-xnyvsi5jla-ew.a.run.app"
            this.calendarApihostEnv = "https://mential-calendar-xnyvsi5jla-ew.a.run.app"
            this.dbEnv = "stg"
            this.googleApiKey = "AIzaSyCqgH9n7za042GtxMSdlVSV2_SvDdeqWi4"
            this.dbCred = {

                apiKey: "AIzaSyAjo8yRyXB_fVw9qrgPwAf-wPzara5FZHY",
                authDomain: "mential-uat.firebaseapp.com",
                projectId: "mential-uat",
                storageBucket: "mential-uat.appspot.com",
                messagingSenderId: "395020812912",
                appId: "1:395020812912:web:f69c9a63fb504851426ab5",
                measurementId: "G-0SVXJVRC57",
                databaseURL: "https://mential-uat-default-rtdb.europe-west1.firebasedatabase.app",

            }
            this.bucketRecords = "gs://mential-sessions-records-uat"
            this.messagingApiHostEnv = "https://mential-messaging-xnyvsi5jla-ew.a.run.app"
            this.cloudMessagingVapidKey = "BKjhB0auNvme5nO3JdkOaxDswzucQlCBeGeCtlrk3_qdaqVF8I7SM47H0R17XKAwQYey8Evj-HQP9_kz3GENZcA"

        }
    }

}
