import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

let env = "prod";
if(window.location.hostname === "localhost") {
    env = "dev";
} else if(window.location.hostname === "mential-uat.web.app") {
    env = "uat";
}


i18n
    .use(Backend) // Configurar el backend HTTP

    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: `https://storage.googleapis.com/mential-public-assets/localization/webapp/${env}/{{lng}}/translation.json`,
        },
        requestOptions: {
            mode: 'cors', // Asegúrate de habilitar CORS
        },
})
;

export default i18n;
